import { isImageItem, isSvgItem, isTextItem } from ".";
import { GetImageSingleColor } from "../../context/EditorDataProvider";
import { ECustomerFields } from "../../enums/customer-fields.enum";
import {
    IEditorDataItem,
    IEditorDataPage,
    IEditorDataPageItemSvg,
    IEditorDataPageItemText,
    IEditorDataV2,
    ISharedWithAllPages,
    TEditorDataItemTypes,
} from "../../interfaces/editor-data.interface";
import { getImage } from "../editor-items";
import recreateSvg from "../svg/recreateSvg";
import updateStyleSvg from "../svg/updateStyleSvg";
import { CM2POINTS, CM2PX } from "../utils";

const processPageItems = async (
    page: IEditorDataPage,
    savePhotos: (item: any) => void,
    getImageSingleColor: GetImageSingleColor,
): Promise<IEditorDataPage> => {
    const { graphicDesignerDimensions = null, cssWidth, cssHeight } = page;
    let scale = null;
    if (graphicDesignerDimensions && cssWidth && cssHeight) {
        const scaleX = cssWidth / graphicDesignerDimensions.width;
        const scaleY = cssHeight / graphicDesignerDimensions.height;
        scale = Math.min(scaleX, scaleY);
    }

    const items = await Promise.all(
        page.items.map(async (item) => {
            item = await adjustImageCustomerRatio(item);

            if (!item.id && isImageItem(item)) {
                item = getImage(page, item);
            }

            //Single color.
            if (page?.printColor) {
                item = await singleColorItem(item, page, getImageSingleColor);
            }

            //Save Photos.
            if (isImageItem(item) && item.base64) {
                savePhotos(item);
            }

            //Scale item.
            if (scale) {
                return scaleItem(item, scale);
            }

            return item;
        }),
    );

    delete page.graphicDesignerDimensions;

    return {
        ...page,
        items,
    };
};

const singleColorItem = async (
    item: IEditorDataItem,
    page: IEditorDataPage,
    getImageSingleColor: GetImageSingleColor,
): Promise<TEditorDataItemTypes> => {
    const { printColor } = page;

    if (isImageItem(item)) {
        return await getImageSingleColor(page, item);
    }

    if (isTextItem(item)) {
        if (item.color === printColor) return item;
        return { ...item, color: printColor as string };
    }

    if (isSvgItem(item)) {
        if (item.borderColor === printColor) return item;

        const { borderWidth, backgroundColor } = item;
        const newBackgroundColor =
            backgroundColor === "none" ? backgroundColor : (
                (printColor as string)
            );
        const svg = updateStyleSvg(item.svg, {
            fill: newBackgroundColor,
            stroke: printColor as string,
            strokeWidth: borderWidth,
        });
        return {
            ...item,
            svg,
            borderColor: printColor as string,
            backgroundColor: newBackgroundColor,
        };
    }

    return item;
};

const getRealImageDimensions = (
    imageSrc: string,
): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = (err) => reject(err);
        img.src = imageSrc;
    });
};

const adjustImageCustomerRatio = async (
    item: IEditorDataItem,
): Promise<IEditorDataItem> => {
    if (
        isImageItem(item) &&
        item.customerField &&
        item.customerField === ECustomerFields.LOGO
    ) {
        try {
            const { width: originalWidth, height: originalHeight } =
                await getRealImageDimensions(item.storageSrc as string);

            const ratio = originalWidth / originalHeight;

            let newWidth = item.width;
            let newHeight = item.height;

            if (newWidth / newHeight > ratio) {
                newWidth = newHeight * ratio;
            } else {
                newHeight = newWidth / ratio;
            }

            const newLeft = item.left + (item.width - newWidth) / 2;
            const newTop = item.top + (item.height - newHeight) / 2;
            delete item.customerField;
            return {
                ...item,
                width: newWidth,
                height: newHeight,
                left: newLeft,
                top: newTop,
            };
        } catch (error) {
            return item;
        }
    }
    return item;
};

const scaleItem = (item: IEditorDataItem, scale: number) => {
    const scaledItem = {
        ...item,
        width: item.width * scale,
        height: item.height * scale,
        top: item.top * scale,
        left: item.left * scale,
    };

    if (isTextItem(item)) {
        (scaledItem as IEditorDataPageItemText).fontSize = Math.ceil(
            item.fontSize * scale,
        );
    } else if (isImageItem(item) && item.cropElement) {
        (scaledItem as IEditorDataPageItemText).cropElement = {
            width: item.cropElement.width * scale,
            height: item.cropElement.height * scale,
            leftDelta: item.cropElement.leftDelta * scale,
            bottomDelta: item.cropElement.bottomDelta * scale,
            x: item.cropElement.x * scale,
            y: item.cropElement.y * scale,
        };
    } else if (isSvgItem(item)) {
        const svg = recreateSvg(item, {
            width: scaledItem.width,
            height: scaledItem.height,
        });
        (scaledItem as IEditorDataPageItemSvg).svg = svg as string;
    }

    return scaledItem;
};

const applyDimensionConversions = (page: IEditorDataPage) => ({
    ...page,
    widthPoints: CM2POINTS(page.width),
    heightPoints: CM2POINTS(page.height),
    cssWidth: CM2PX(page.width),
    cssHeight: CM2PX(page.height),
});

export const migrateEditorDataV2 = async (
    editorData: IEditorDataPage[] | IEditorDataV2,
    savePhotos: (item: any) => void,
    getImageSingleColor: GetImageSingleColor,
): Promise<IEditorDataV2> => {
    if (!Array.isArray(editorData)) {
        const pages = await Promise.all(
            editorData.pages.map((page) =>
                processPageItems(
                    applyDimensionConversions(page),
                    savePhotos!,
                    getImageSingleColor,
                ),
            ),
        );

        return {
            ...editorData,
            pages,
        };
    }

    const sharedWithAllPages: ISharedWithAllPages =
        editorData[0]?.sharedWithAllPages || {};

    const pages = await Promise.all(
        editorData.map((page) =>
            processPageItems(
                applyDimensionConversions(page),
                savePhotos!,
                getImageSingleColor,
            ),
        ),
    );

    return {
        pages,
        ...sharedWithAllPages,
    };
};
