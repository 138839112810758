import useCustomerFields from "../../../hooks/useCustomerFields";
import SimpleSelect from "../../inputs/toggle-switch/simple-select/SimpleSelect";
import styled from "styled-components";
import {
    IEditorDataItem,
    IEditorDataPageItemText,
} from "../../../interfaces/editor-data.interface";
import { useEditorDataContext } from "../../../context/EditorDataProvider";

const StyledWrap = styled.div`
    max-width: 200px;
    margin-left: var(--small-gap);
`;
const CustomerFields = () => {
    const { filteredFields } = useCustomerFields();
    const { updateItems, getCurrentPageData, editorData } =
        useEditorDataContext();
    const { customerFields = {} } = editorData;

    const items = getCurrentPageData().items as IEditorDataPageItemText[];
    const focusedItem = items.find((item: IEditorDataItem) => item.focus);

    if (!focusedItem?.customerField) return null;

    const options = filteredFields.map((field: string) => ({
        label: field,
        value: field,
    }));

    return (
        <StyledWrap>
            <SimpleSelect
                includeChooseOption={false}
                options={options}
                selected={focusedItem.customerField}
                handleSelect={(option) => {
                    updateItems(
                        items.map((item) => {
                            if (item.focus && item.customerField) {
                                item.content = customerFields[option] || "";
                                item.customerField = option;
                            }
                            return item;
                        }),
                    );
                }}
            />
        </StyledWrap>
    );
};

export default CustomerFields;
