import { useMemo } from "react";
import { useEditorDataContext } from "../context/EditorDataProvider";
import { ECustomerFields } from "../enums/customer-fields.enum";

const useCustomerFields = () => {
    const { editorData } = useEditorDataContext();
    const { customerFields = {} } = editorData;

    const { customerFieldsArray, filteredFields, hasLogoField } =
        useMemo(() => {
            const customerFieldsArray = Object.keys(customerFields);

            const filteredFields = customerFieldsArray.filter(
                (field) => field !== ECustomerFields.LOGO,
            );

            const hasLogoField = customerFieldsArray.includes(
                ECustomerFields.LOGO,
            );

            return { customerFieldsArray, filteredFields, hasLogoField };
        }, [customerFields]);

    return { customerFieldsArray, filteredFields, hasLogoField };
};

export default useCustomerFields;
