import { useTranslation } from "react-i18next";
import { StyledSideBarSubTitle } from "../../../style-components/styleComponents";
import PhotoItem from "../../items/photoItem";
import { nanoid } from "nanoid";
import logo from "../../../../assets/images/customer-logo.jpg";
import { ECustomerFields } from "../../../../enums/customer-fields.enum";

import { useEditorDataContext } from "../../../../context/EditorDataProvider";

const isValidUrl = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

const LogoField = () => {
    const { t } = useTranslation();
    const { editorData } = useEditorDataContext();
    const { customerFields = {} } = editorData;
    const customerLogo = customerFields[ECustomerFields.LOGO] as string;

    const isValidLogoUrl = isValidUrl(customerLogo);

    const id = nanoid();

    return (
        <>
            <StyledSideBarSubTitle>
                {t("addCustomerFieldLogo")}
            </StyledSideBarSubTitle>
            <PhotoItem
                id={id}
                base64={!isValidLogoUrl ? logo : undefined}
                src={isValidLogoUrl ? customerLogo : undefined}
                itemImage={{
                    customerField: ECustomerFields.LOGO,
                }}
            />
        </>
    );
};

export default LogoField;
